@import "../../../styles/mixins.scss";

.cities-section {
    margin: 0;
    padding-bottom: 10vh;
    min-height: 85vh;

    &.video-title {
        padding-top: 10vh;
        padding-bottom: 5vh;
        min-height: 5vh;
        font-size: 2.5rem;
        align-self: center;
        text-align: center;
        width: 100%;
        color: #FFFFFF;
        display: inline-block;
    }

    &.text-section {
        padding-bottom: 0;
    }

    &.video {
        margin-top: -1px;
        text-align: center;
        min-height: 50vw;

        @include navbar-collapsed {
            min-height: 60vw;
        }

        @include for-phone-only {
            padding: 15vh 0 10vh;
            min-height: 55vh;
        }
    }

    .video-iframe {
        padding: 0 10vw;
        width: 100%;
        height: 100%;

        @include navbar-collapsed {
            padding: 0 10vw;
        }

        @include for-phone-only {
            padding: 0 5vw;
        }
    }

    .cities-two-rows-section-text {
        text-align: justify;
        padding: 10vh 10vh 5vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        display: flex;
        height: auto;

        .cities-two-rows-section-header {
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 5vh;
            text-align: center;
        }

        .cities-two-rows-section-text-wrapper {
            margin: auto;
            width: 60vw;

            @include for-phone-only {
                width: 100%;
            }
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            padding: 5vh;
            font-size: 1rem;
        }
    }

    &.figure-section-wrapper {
        color: #FFFFFF;
        padding-bottom: 0;
        // background-repeat: no-repeat;
        // background-position: center center;
        // background-size: cover;
        // background-image: url(../../../assets/img/home/benefits/hexagons.svg);
    }

    .cities-two-rows-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 0;
        background-size: 60vw;
        min-height: 40vh;

        &.logo-section {
            background-size: 40vh;
            min-height: 25vh;
            padding: 0;
            background-image: url(../../../assets/img/dashboards/cities/cities-logo-section-img.png);

            @include for-big-screens {
                min-height: 30vh;
            }

            @include navbar-collapsed {
                min-height: 40vh;
                background-size: 50vw;
            }

            @include for-phone-only {
                min-height: 20vh;
                background-size: 80vw;
            }
        }

        &.figure-section {
            @include for-big-screens {
                min-height: 65vh;
            }
    
            @include navbar-collapsed {
                min-height: 40vh;
                background-size: 80vw;
            }
    
            @include for-phone-only {
                background-size: 90vw;
                min-height: 20vh;
            }
            
            background-image: url(../../../assets/img/dashboards/cities/cities-figure-section-img.png);
        }
    }

    &.text-section {
        color: #4D4D4D;

        .cities-text-section {
            min-height: 30vh;
            display: flex;
            height: auto;

            &.third {
                background: transparent linear-gradient(180deg, #EEEEEF 70%, #F0D4B8 100%) 0% 0% no-repeat padding-box;;
            }

            &.second {
                background-color: #EEEEEF;
            }

            @include for-xs {
                &.first {
                    background-color: #EEEEEF;
                }
                &.second {
                    background-color: transparent;
                }
            }

            .cities-text-wrapper {
                font-size: 1.5rem;

                .cities-text-header {
                    font-size: 2rem;
                    padding-bottom: 1vh;
                    text-align: left;
                    font-weight: bold;
                }

                margin: auto;
                text-align: justify;
                width: 45%;
                padding: 5vh 0;

                @include navbar-collapsed {
                    width: 65%;
                }

                @include for-phone-only {
                    width: 75%;
                }
            }
        }
    }

    &.visit-platform-button-section {
        min-height: 15vh;
        padding-top: 5vh;
        padding-bottom: 0;
        z-index: 1;

        .visit-platform-button {
            text-align: center;
            margin-top: 2vh;
            font-size: 3vh;
            font-weight: bold;
            width: 45vh;
            margin-bottom: 2vh;
            color: #0B5E96;
            background-color: white;
            border: none;
            border-radius: 50px;
            height: 8vh;
            line-height: 8vh;
            font-family: Montserrat Bold;
          
            &:focus {
                outline: none;
            }
          
            &:hover {
                opacity: 0.85;
            }
        }
    }
}

.videos-section {
    background: transparent linear-gradient(180deg, #F0D4B8 10%, #F48515 50%, #361D73 100%) 0% 0% no-repeat padding-box;

    .blocks {
        padding-top: 10vh;
        position: absolute;
        width: 100vw;
        left: 0;
        z-index: 0;
        opacity: 0.72;

        @include navbar-collapsed {
            padding-top: 20vh;
        }

        @include for-md {
            padding-top: 25vh;
        }

        @include for-phone-only {
            padding-top: 35vh;
        }
    }

    .hexagons {
        padding-top: 15vh;
        position: absolute;
        width: 100vw;
        left: 0;
        z-index: 0;
        opacity: 0.4;

        @include for-desktop-up {
            padding-top: 10vh;
        }

        @include navbar-collapsed {
            padding-top: 20vh;
            width: 140vw;
        }

        @include for-md {
            padding-top: 30vh;
            width: 150vw;
        }

        @include for-phone-only {
            padding-top: 35vh;
            width: 180vw;
        }
    }
}