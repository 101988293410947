@import "../../../styles/mixins.scss";

.campus-section {
    margin: 0;
    padding-bottom: 10vh;
    min-height: 85vh;

    &.text-section {
        padding-bottom: 0;
    }

    &.video {
        margin-top: -1px;
        background-color: #FDC200;
        padding: 10vh 0;
        text-align: center;
        min-height: 45vw;

        @include navbar-collapsed {
            min-height: 60vw;
        }

        @include for-phone-only {
            padding: 15vh 0 10vh;
            min-height: 55vh;
        }
    }

    .video-iframe {
        padding: 0 20vw;
        width: 100%;
        height: 100%;

        @include navbar-collapsed {
            padding: 0 10vw;
        }

        @include for-phone-only {
            padding: 0 5vw;
        }
    }

    .campus-two-rows-section-text {
        text-align: justify;
        padding: 10vh 10vh 5vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        display: flex;
        height: auto;

        .campus-two-rows-section-header {
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 5vh;
            text-align: center;
        }

        .campus-two-rows-section-text-wrapper {
            margin: auto;
            width: 60vw;

            @include for-phone-only {
                width: 100%;
            }
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            padding: 5vh;
            font-size: 1rem;
        }
    }

    &.figure-section {
        background-color: #0B5E96;
        color: #FFFFFF;
    }

    .campus-two-rows-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;
        background-size: 60vw;
        min-height: 55vh;

        &.figure-section {
            background-image: url(../../../assets/img/dashboards/campus/campus-figure-section-img.png);
        }

        @include for-big-screens {
            min-height: 70vh;
        }

        @include navbar-collapsed {
            min-height: 35vh;
        }

        @include for-phone-only {
            background-size: 90vw;
            min-height: 35vh;
        }
    }

    &.text-section {
        color: #4D4D4D;

        .campus-text-section {
            min-height: 30vh;
            display: flex;
            height: auto;

            &.sixth,
            &.third,
            &.second {
                background-color: #EEEEEF;
            }

            @include for-xs {
                &.fifth,
                &.first {
                    background-color: #EEEEEF;
                }
                &.sixth,
                &.second {
                    background-color: transparent;
                }
            }

            .campus-text-wrapper {
                font-size: 1.1rem;

                .campus-text-header {
                    font-size: 1.5rem;
                    padding-bottom: 1vh;
                    text-align: left;
                }

                margin: auto;
                text-align: justify;
                width: 45%;
                padding: 5vh 0;

                @include navbar-collapsed {
                    width: 65%;
                }

                @include for-phone-only {
                    width: 75%;
                }
            }
        }
    }
}