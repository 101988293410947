@import "../../../styles/mixins.scss";

.lite-campus-section {
    height: auto;
    background-color: #0D6EAF;
    margin: 0;
    margin-bottom: -1px;

    .lite-campus-text-wrapper {
        padding: 10vh 1vh;
        background-color: #0D6EAF;
        color: #FFF;
        display: flex;
        min-height: 50vh;
        font-size: 1.2rem;

        @include for-md {
            padding: 5vh 1vh;
            min-height: 30vh;
        }

        .lite-campus-text {
            margin: auto auto auto 7vw;
            width: 80%;
            text-align: justify;

            b {
                font-family: Montserrat Bold;
            }

            @include navbar-collapsed {
                margin: auto;
            }
            
            @include for-phone-only {
                width: 90%;
            }
        }
    }

    .lite-campus-sub-section {
        background-color: #0D6EAF;
        display: grid;
        min-height: 50vh;

        @include for-md {
            background-color: #0D6EAF;
            min-height: 30vh;
        }

        .lite-campus-artwork {
            background-color: #0D6EAF;
            color: #FFFFFF;
            margin: auto;
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            height: auto;

            @include for-phone-only {
                font-size: 2.5rem;
            }
    
            .lite-campus-img {
                background: url(../../../assets/img/home/lite-campus/liteme-maps-white.svg) no-repeat;
                background-size: 35vw;
                background-position: center;
                width: 35vw;
                height: 35vw;

                @include for-md {
                    background-size: 50vw;
                    width: 50vw;
                    height: 50vw;
                }

                @include for-phone-only {
                    background-size: 70vw;
                    width: 70vw;
                    height: 70vw;
                }
            }
        }
    }
}