@import "../../../styles/mixins.scss";

.home-dashboards-section {
    background-color: #EEEEEF;
    margin: 0;

    .home-dashboards-logos {
        width: 55%;
        margin: auto;

        @include for-big-screens {
            width: 35%;
        }

        @include navbar-collapsed {
            width: 75%;
        }

        @include for-phone-only {
            width: 95%;
        }
    }

    .home-dashboards-txt {
        margin: auto;
        padding: 0 5vh;
        color: #4D4D4D;
        font-size: 1.2rem;
        text-align: center;
        width: 55%;
        padding-top: 3vh;
        padding-bottom: 5vh;

        @include for-big-screens {
            width: 35%;
        }

        @include navbar-collapsed {
            width: 75%;
        }

        @include for-phone-only {
            width: 95%;
            font-size: 1.1rem;
            padding: 0 15px;
            padding-top: 5vh;
        }
    }

    .home-dashboards-txt-second-row {
        margin: auto;
        color: #4D4D4D;
        font-size: 1.2rem;
        text-align: center;
        width: 85%;
        padding-top: 3vh;
        padding-bottom: 3vh;
    
        @include for-phone-only {
            width: 100%;
            font-size: 1.1rem;
            padding-top: 5vh;
        }
    }

    .home-dashboards-logo {
        background-repeat: no-repeat;
        background-position: center center;
        text-align: center;
        color: #0B5E96;
        font-weight: bold;
        font-size: 1.2rem;
        background-size: 8vh;
        height: 15vh;
        padding-top: 12vh;
        justify-content: center;

        @include for-phone-only {
            font-size: 1rem;
        }

        &.commerce {
            background-image: url(../../../assets/img/logo/commerce-logo.png);
        }

        &.industry {
            background-size: 6vh;
            background-image: url(../../../assets/img/logo/industry-logo.png);
        }

        &.residence {
            background-image: url(../../../assets/img/logo/residence-logo.png);
        }
        &.concessionary {
            background-size: 7vh;
            padding-top: 14vh;
            background-image: url(../../../assets/img/logo/concessionary-logo.png);
        }
        &.campus {
            background-size: 19vh;
            padding-top: 14vh;
            background-image: url(../../../assets/img/logo/liteme-maps-blue-logo.svg);
        }
    }

    .home-dashboards-img {
        background: url(../../../assets/img/home/areas-dashboard/home-dashboards-img.png) center no-repeat;
        background-size: contain;
        width: auto;
        height: 30vw;
        margin-top: 5vh;
        margin-bottom: 5vh;

        @include navbar-collapsed {
            height: 34vw;
        }
    }

    .home-app-txt {
        color: #0B5E96;
        font-size: 1.2rem;
        text-align: center;
        padding-bottom: 2vh;
    }

    .home-app-buttons {
        margin: auto;
        width: fit-content;
        padding: 0;

        @include for-phone-only {
            padding-bottom: 3vh;
        }

        .app-button {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12vh;
            width: 13vh;
            height: 5vh;
            cursor: pointer;
    
            &.app-store {
                background-image: url(../../../assets/img/home/areas-dashboard/app-store.svg);
            }
            &.google-play {
                background-size: 15vh;
                width: 14vh;
                background-image: url(../../../assets/img/home/areas-dashboard/google-play.png);
            }
        }
    }

    .meet-dashboards-txt {
        font-family: Montserrat Bold;
        font-size: 1.3rem;
        font-weight: bold;
        width: fit-content;
        color: #0B5E96;
        height: 6vh;
        margin: 5vh 17vh 5vh auto;
        line-height: 6vh;
        cursor: pointer;

        @include for-big-screens {
            margin: 5vh 23vh 5vh auto;
        }

        @include navbar-collapsed {
            margin: 5vh 8vh 5vh auto;
        }

        @include for-phone-only {
            margin: 5vh auto;
            font-size: 1rem;
            text-align: center;
        }
    }

    .right-arrow-dashboards {
        position: absolute;
        top: 49.5%;
        width: 2vmin;
        height: 2vmin;
        background: transparent;
        border-top: 1vmin solid #0B5E96;
        border-right: 1vmin solid #0B5E96;
        transition: all 200ms ease;
        right: 15vh;
        transform: translate3d(0,-50%,0) rotate(45deg);

        @include for-big-screens {
            right: 20vh;
        }

        @include navbar-collapsed {
            top: 49%;
            right: 5vh;
        }

        @include for-phone-only {
            top: 49%;
            right: 7vh;
        }
        
        &:hover {
            border-color: #FDC200;
            box-shadow: 0.5vmin -0.5vmin 0 #0B5E96;
        }
        
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-40%,-60%) rotate(45deg);
            width: 200%;
            height: 200%;
        }
    }
}