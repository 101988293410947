@import "../../../../styles/mixins.scss";

.block-modal-container {
    position: absolute;
    z-index: 1000;
    width: 700px;
    height: 55vh;
    background-color: white;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    border-radius: 10px;
    font-family: Ubuntu;
    top: 0;
    margin: 2.5vh;
    -webkit-box-shadow: 10px 10px 6px #0000004f;
    -moz-box-shadow: 10px 10px 6px #0000004f;
    box-shadow: 10px 10px 6px #0000004f;
  }
  
  .block-modal-container.hide {
    opacity: 0;
  }
  
  .block-modal-header {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: white;
    transition: background-color 0.15s linear;
  }
  
  .block-modal-header.energy {
    background-color: #ffb744;
  }
  
  .block-modal-header.water {
    background-color: #007c9f;
  }
  
  .block-modal-header-title {
    margin-left: 20px;
    margin-top: 5px;
    font-weight: bold;
    flex: 6;
  }
  
  .close-button-container {
    flex: 1;
  }
  
  .close-button {
    border-radius: 50px;
    border-width: 2px;
    border-style: solid;
    height: 20px;
    width: 20%;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
  
  .change-type-container {
    flex: 1;
    display: flex;
  }
  
  .block-modal-header-container {
    display: flex;
    padding: 10px;
    height: 50px;
  }
  
  .change-type-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-image: url("../../../../assets/img/map/hexagon-outline.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .change-type-button-energy {
    background-image: url("../../../../assets/img/map/hexagon-energy.png");
  }
  .change-type-button-water {
    background-image: url("../../../../assets/img/map/hexagon-water.png");
  }
  
  .change-type-button.change-type-active {
    background-image: url("../../../../assets/img/map/hexagon-outline.png") !important;
  }
  
  .block-modal-body {
    margin: auto;
    height: 85%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .block-data-container {
    display: flex;
    flex-direction: row;
    height: 60%;
  }
  
  .block-bars-column {
    align-self: flex-end;
  }
  
  .block-data-column {
    flex: 1;
    height: 100%;
  }
  
  .left-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: auto;
    margin-left: 20px;
    margin-top: 20px;
  }
  
  .right-container {
    flex: 1;
    top: -5%;
    position: relative;
    margin-left: 10%;
  }
  
  .block-name-container {
    margin: 10px;
    height: 20%;
  }
  
  .block-modal-body-title {
    color: #888888;
    font-size: 28px;
  }
  
  .block-modal-body-subtitle {
    color: #c2c2c2;
    font-size: 10px;
  }
  
  .block-modal-body-date {
    font-size: 28px;
    font-weight: bold;
  }
  
  .block-modal-body-date-energy {
    color: #f8be22;
  }
  
  .block-modal-body-date-water {
    color: #007c9f;
  }
  
  .block-modal-body-month {
    font-size: 20px;
    font-weight: normal;
  }
  
  .block-modal-body-legend {
    color: rgba(136, 136, 136, 0.5);
    font-size: 10px;
  }
  
  .hexagon {
    position: relative;
    width: 100px;
    height: 57.74px;
    background-color: #ffb744;
    margin: 28.87px 0;
    border-left: solid 2px #ffffff;
    border-right: solid 2px #ffffff;
    top: 15%;
    transition: background-color 0.15s linear;
  }
  
  .hexagon.energy {
    background-color: #ffb744;
  }
  
  .hexagon.water {
    background-color: #007c9f;
  }
  
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 70.71px;
    height: 70.71px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 12.6447px;
  }
  
  .hexagon:before {
    top: -35.3553px;
    border-top: solid 2.8284px #ffffff;
    border-right: solid 2.8284px #ffffff;
  }
  
  .hexagon:after {
    bottom: -35.3553px;
    border-bottom: solid 2.8284px #ffffff;
    border-left: solid 2.8284px #ffffff;
  }
  
  .hexagon-text {
    position: absolute;
    z-index: 1000;
    width: 100%;
    text-align: center;
    color: white;
  }
  
  .hexagon-unit {
    font-size: 10px;
  }
  
  .hexagon-legend {
    text-align: center;
    width: 100px;
    bottom: 30%;
    position: absolute;
  }
  
  .hexagon-legend-energy {
    color: #ffb744;
  }
  
  .hexagon-legend-water {
    color: #007c9f;
  }
  
  .more-details-button-container {
    height: 20%;
    text-align: right;
    margin-right: 15px;
  }
  
  .more-details-button {
    font-size: 10px;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 20px;
    background-color: transparent;
    cursor: pointer;

    a:hover {
      text-decoration: none;
      color: orange;
    }
  }
  
  .more-details-button-energy {
    color: rgba(248, 190, 34, 1);
    border: 1px solid rgba(248, 190, 34, 1);
  }
  
  .more-details-button-water {
    color: #007c9f;
    border: 1px solid #007c9f;
  }
  
  .more-details-button:hover {
    background: #e8e8e8;
    text-decoration: none;
  }
  
  .more-details-button-triangle-energy {
    width: 0;
    position: absolute;
    height: 0;
    border-bottom: 10px solid rgba(248, 190, 34, 1);
    border-left: 10px solid transparent;
    right: 15px;
    border: 10p;
    transform: translateY(-5px);
  }
  .more-details-button-triangle-water {
    width: 0;
    position: absolute;
    height: 0;
    border-bottom: 10px solid #007c9f;
    border-left: 10px solid transparent;
    right: 15px;
    border: 10p;
    transform: translateY(-5px);
  }
  
  .more-details-link-energy {
    text-decoration: none;
    color: orange;
  }
  
  .more-details-link-water {
    text-decoration: none;
    color: #007c9f;
  }
  
  .block-icon-energy {
    width: 70%;
    height: 60%;
    margin: auto;
    background-image: url("../../../../assets/img/map/block-icon-energy.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .block-icon-water {
    width: 70%;
    height: 60%;
    margin: auto;
    background-image: url("../../../../assets/img/map/block-icon-water.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .block-icon-legend {
    text-align: center;
    height: 40%;
  }
  
  .block-icon-month-energy {
    color: #f8be22;
    font-weight: bold;
  }
  
  .block-icon-month-water {
    color: #007d9f;
    font-weight: bold;
  }
  
  .block-icon-year {
    color: #ababab;
  }
  
  .block-data-value {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: white;
    top: 50%;
    text-align: center;
    transform: translateY(-30%);
  }
  
  .block-data-unit {
    padding: 2px;
    font-size: 14px;
    font-weight: normal;
  }
  
  .block-data-energy {
    width: 70%;
    height: 60%;
    margin: auto;
    background-image: url("../../../../assets/img/map/block-data-energy.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .block-data-water {
    width: 70%;
    height: 60%;
    margin: auto;
    background-image: url("../../../../assets/img/map/block-data-water.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .close-button-image {
    height: 70%;
    margin-top: 5px;
    margin-right: 5px;
    padding: -10px;
    background-image: url("../../../../assets/img/map/fechar.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .block-name-container {
      height: 10%;
    }
    .block-modal-body-title {
      font-size: 14px;
    }
    .block-modal-container {
      position: relative;
      width: calc(100% - 40px);
      margin: 20px;
      height: auto;
    }
    .block-data-container {
      flex-direction: column;
      height: 80%;
    }
    .more-details-button-container {
      height: 10%;
    }
    .block-modal-header-title {
      flex: 4;
    }
    .block-modal-body {
      flex-direction: column;
      height: 550px;
    }
    .hexagon-legend {
      display: none;
    }
    .right-container {
      margin-left: 40%;
    }
    .block-bars-column {
      align-self: center;
    }
  }
  
  .graphic {
    height: 60%;
  }