@import "../../../styles/mixins.scss";

.dashboard-header-bg {

  &.concessionary {
    background-color: #0B5E96;
    color: #FFFFFF;
    font-size: 2.2rem;
  }
  &.campus {
    background-color: #FDC200;
    color: #FFFFFF;
  }
  &.cities {
    background-color: #EEEEEF;
    color: #4D4D4D;
  }

  .dashboard-header {
    background-position: center bottom;
    background-size: 15vh;
    background-repeat: no-repeat;
    height: 25vh;
    width: auto;
  
    &.residence {
      background-size: 20vh;
      background-image: url(../../../assets/img/logo/residence-header-logo.svg);
    }
    &.commerce {
      background-size: 20vh;
      background-image: url(../../../assets/img/logo/commerce-header-logo.png);
    }
    &.industry {
      background-image: url(../../../assets/img/logo/industry-header-logo.png);
    }
    &.concessionary {
      background-size: 12vh;
      background-image: url(../../../assets/img/logo/concessionary-header-logo.png);
    }
    &.campus {
      background-size: 30vh;
      background-image: url(../../../assets/img/logo/campus-header-logo.png);
    }
    &.cities {
      background-size: 40vh;
      background-image: url(../../../assets/img/logo/cities-header-logo.svg);
    }
  }
  
  .dashboard-header-label {
    width: 100%;
    font-family: Montserrat Bold;
    text-align: center;
    display: inline-block;
    font-size: 3rem;
    padding-top: 4vh;
    padding-bottom: 5vh;
    color: #FDC200;
    
    &.concessionary {
      color: #FFFFFF;
      font-size: 2.2rem;
    }
    &.campus {
      color: #FFFFFF;
      padding-bottom: 0;
    }
  }
}