@import "../../../styles/mixins.scss";

.residence-section {
    height: auto;
    margin: 0;

    &.residence-boxes-section,
    &.always-on-section,
    &.period-consumption-section {
        @include for-md {
            flex-direction: column-reverse;
        }
    }

    .residence-two-columns-section-text {
        text-align: justify;
        padding: 7vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        color: #4D4D4D;
        display: flex;
        min-height: 70vh;
        background-color: #EEEEEF;

        .residence-two-columns-section-text-wrapper {
            margin: auto;
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            min-height: 40vh;
            font-size: 1rem;
        }

        .residence-two-columns-section-header {
            text-align: left;
            font-size: 2rem;
            font-weight: bold;
        }
    }

    .residence-two-columns-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;

        &.residence-boxes-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-boxes-section-img.png);
            background-size: 35vh;
            min-height: 70vh;

            &.for-commerce {
                background-image: url(../../../assets/img/dashboards/residence/commerce-boxes-section-img.png);
            }

            @include for-big-screens {
                background-size: 40vh;
            }

            @include navbar-collapsed {
                background-size: 35vh;
            }

            @include for-phone-only {
                background-size: 65vw;
            }
        }

        &.efficiency-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-efficiency-section-img.png);
            background-size: 55vh;
            min-height: 50vh;

            &.for-commerce {
                background-image: url(../../../assets/img/dashboards/residence/commerce-efficiency-section-img.png);
            }

            @include for-big-screens {
                background-size: 60vh;
            }

            @include navbar-collapsed {
                background-size: 45vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 40vh;
            }
        }

        &.always-on-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-always-on-section-img.png);
            background-size: 55vh;
            min-height: 70vh;

            &.for-commerce {
                background-image: url(../../../assets/img/dashboards/residence/commerce-always-on-section-img.png);
            }

            @include for-big-screens {
                background-size: 60vh;
            }

            @include navbar-collapsed {
                background-size: 45vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 40vh;
            }
        }

        &.devices-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-devices-section-img.png);
            background-size: 55vh;
            min-height: 70vh;

            @include for-big-screens {
                background-size: 60vh;
            }

            @include navbar-collapsed {
                background-size: 40vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 60vh;
            }
        }

        &.period-consumption-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-period-consumption-section-img.png);
            background-size: 55vh;
            min-height: 70vh;

            &.for-commerce {
                background-image: url(../../../assets/img/dashboards/residence/commerce-period-consumption-section-img.png);
            }

            @include for-big-screens {
                background-size: 60vh;
            }

            @include navbar-collapsed {
                background-size: 45vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 50vh;
            }
        }

        &.recommendations-section {
            background-image: url(../../../assets/img/dashboards/residence/residence-recommendations-section-img.png);
            background-size: 55vh;
            min-height: 70vh;

            @include for-big-screens {
                background-size: 60vh;
            }

            @include navbar-collapsed {
                background-size: 45vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 50vh;
            }
        }

    }
}

.residence-initial-text {
    margin: 0;
    margin-top: -1px;
    color: #4D4D4D;
    font-size: 1.8rem;
    text-align: justify;
    padding-top: 10vh;
    padding-bottom: 20vh;

    @include for-xs {
        font-size: 1.5rem;
    }

    .residence-initial-text-col {
        margin: auto;
    }
}