@import "../../../styles/mixins.scss";

.industry-section {
    height: auto;
    margin: 0;
    padding: 10vh 0vh;

    &.video {
        margin-top: -1px;
        padding: 10vh 0;
        text-align: center;
        min-height: 45vw;
        background-color: #EEEEEF;

        @include navbar-collapsed {
            min-height: 60vw;
        }

        @include for-phone-only {
            padding: 15vh 0 10vh;
            min-height: 55vh;
        }
    }

    .video-iframe {
        padding: 0 20vw;
        width: 100%;
        height: 100%;

        @include navbar-collapsed {
            padding: 0 10vw;
        }

        @include for-phone-only {
            padding: 0 5vw;
        }
    }

    &.boxes-section {
        padding: 0 0 10vh;
    }

    &.peak-hour-section,
    &.factor-section,
    &.bill-section {
        background-color: #EEEEEF;
    }

    .industry-two-rows-section-text {
        text-align: justify;
        padding: 5vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        color: #4D4D4D;
        display: flex;
        height: auto;

        .industry-two-rows-section-header {
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 5vh;
            text-align: left;
        }

        .industry-two-rows-section-text-wrapper {
            margin: auto;
            width: 80vw;

            @include for-phone-only {
                width: 100%;
            }
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            font-size: 1rem;
        }
    }

    .industry-two-rows-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;

        &.peak-hour-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-peak-hour-section-img.png);
            background-size: 55vw;
            min-height: 70vh;

            @include for-big-screens {
                background-size: 52.5vw;
                min-height: 90vh;
            }

            @include navbar-collapsed {
                background-size: 70vw;
                min-height: 60vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 30vh;
            }
        }

        &.factor-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-factor-section-img.png);
            background-size: 80vw;
            min-height: 50vh;

            @include for-big-screens {
                min-height: 60vh;
            }

            @include navbar-collapsed {
                background-size: 90vw;
                min-height: 35vh;
            }

            @include for-phone-only {
                min-height: 25vh;
            }
        }

        &.industry-report-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-industry-report-section-img.png);
            background-size: 80vw;
            min-height: 70vh;

            @include for-big-screens {
                min-height: 80vh;
            }

            @include navbar-collapsed {
                background-size: 75vw;
                min-height: 45vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 25vh;
            }
        }
    }

    .industry-two-columns-section-text {
        text-align: justify;
        padding: 5vh 5vh 5vh 20vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        color: #4D4D4D;
        display: flex;
        min-height: 70vh;

        .industry-two-columns-section-text-wrapper {
            margin: auto;
        }

        .industry-two-columns-section-header {
            text-align: left;
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 5vh;
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            min-height: 20vh;
            font-size: 1rem;
        }
    }

    .industry-two-columns-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;

        &.boxes-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-boxes-section-img.png);
            background-size: 35vh;
            min-height: 70vh;

            @include for-big-screens {
                background-size: 40vh;
            }

            @include navbar-collapsed {
                background-size: 35vh;
            }

            @include for-phone-only {
                background-size: 65vw;
            }
        }

        &.table-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-table-section-img.png);
            background-size: 75vh;
            min-height: 50vh;

            @include navbar-collapsed {
                background-size: 60vh;
                min-height: 60vh;
            }

            @include for-md {
                background-size: 90vw;
                min-height: 60vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 35vh;
            }
        }

        &.bill-section {
            background-image: url(../../../assets/img/dashboards/industry/industry-bill-section-img.png);
            background-size: 55vh;
            min-height: 50vh;

            @include navbar-collapsed {
                min-height: 60vh;
            }

            @include for-phone-only {
                background-size: 80vw;
                min-height: 40vh;
            }
        }
    }
}

.industry-initial-text {
    margin: 0;
    margin-top: -1px;
    color: #4D4D4D;
    font-size: 1.8rem;
    text-align: justify;
    padding-top: 5vh;
    padding-bottom: 10vh;

    @include for-xs {
        font-size: 1.5rem;
    }

    .industry-initial-text-col {
        margin: auto;
    }
}