@import "../../../styles/mixins.scss";

.home-header {
    background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.3)), url(../../../assets/img/background/background.png) bottom center no-repeat;
    background-size: cover;
    color: #FFFFFF;
    margin: 0;

    .home-header-img {
        height: 25vh;
        width: 100%;
        background: url(../../../assets/img/logo/liteme/white/horizontal/logo.svg) no-repeat;
        background-position: center;
        background-size: contain;
        position: relative;
        top: 10vh;

        @include for-phone-only {
            top: 15vh;
            height: 15vh;
        }
    }

    .home-header-text-wrapper {
        margin: auto;
        padding-bottom: 10vh;
        text-align: left;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 300;
        text-transform: uppercase;
        display: flex;
        font-family: Montserrat Bold;

        @include navbar-collapsed {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        @include for-phone-only {
            font-size: 1.3rem;
            line-height: 1.8rem;
            padding-bottom: 15vh;
        }

        .home-header-rect {
            background: #F15B29;
            padding: 5px;
            display: flex;
            border-radius: 10px;
        }

        .home-header-text {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 10px 10px 10px 15px;
            backdrop-filter: blur(1px);

            .home-header-subtext {
                margin-top: 5vh;
                font-size: 1.5rem;
                line-height: 2rem;
                font-family: Montserrat;
                text-transform: none;
    
                @include for-phone-only {
                    margin-top: 5vh;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                }
            }
        }
    }
}