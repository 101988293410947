@import "../../../styles/mixins.scss";

.concessionary-section{
    margin: 0;

    .concessionary-main-img {
        background-color: #0B5E96;
        background-image: url(../../../assets/img/dashboards/concessionary/concessionary-main-img.png);
        background-repeat: no-repeat;
        background-position: center center;
        padding: 5vh 0;
        background-size: 100vh;
        min-height: 85vh;

        &.header {
            margin-top: -1px;
        }
    
        @include navbar-collapsed {
            background-size: 80vw;
            min-height: 80vw;
        }
    }

    &.text-section {
        color: #4D4D4D;

        .concessionary-text-section {
            min-height: 30vh;
            display: flex;
            height: auto;

            &.second,
            &.third,
            &.sixth {
                background-color: #EEEEEF;
            }

            @include for-xs {
                &.third {
                    background-color: transparent;
                }
                &.second,
                &.fourth,
                &.sixth {
                    background-color: #EEEEEF;
                }
            }

            .concessionary-text-wrapper {
                font-size: 1.1rem;

                .concessionary-text-header {
                    font-size: 1.5rem;
                    padding-bottom: 1vh;
                    text-align: left;

                    b {
                        font-family: Montserrat Bold;
                    }
                }

                margin: auto;
                text-align: justify;
                width: 45%;
                padding: 5vh 0;

                @include navbar-collapsed {
                    width: 65%;
                }

                @include for-phone-only {
                    width: 75%;
                }
            }
        }
    }
}

.concessionary-initial-text {
    margin-top: -1px;
    background-color: #0B5E96;
    color: #FFFFFF;
    font-size: 1.8rem;
    text-align: justify;
    margin-right: 0;
    margin-left: 0;

    @include for-xs {
        font-size: 1.5rem;
    }

    .concessionary-initial-text-col {
        margin: auto;
    }
}