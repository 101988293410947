@import "../../styles/mixins.scss";

.join-header {
    display: flex;
    flex-wrap: wrap;
    background: #0B5E96 url(../../assets/img/background/join-background.png) bottom center no-repeat;
    background-size: cover;
    color: #FFFFFF;
    position: relative;

    .join-header-txt {
        margin: auto auto 20vw 20vw;
        width: 40%;
        font-size: 8rem;
        line-height: 7rem;
        font-weight: bold;
        font-family: Montserrat Bold;

        @include navbar-collapsed {
            margin: auto auto 20vw 15vw;
        }

        @include for-phone-only {
            margin: auto auto 70vw 5vw;
            font-size: 5rem;
            line-height: 4rem;
        }
    }

    $total-arrows: 3;
    $arrow-line-length: 50px;
    $arrow-line-width: 4px;

    @mixin arrow-transitions($rot: 0deg) {
        transform: translate(-50%, -50%) rotateZ($rot);
    }

    .arrow-container {
        display: block;
        width: 100px;
        height: 100px;  
        position: absolute;
        bottom: 0;
        left: 50%;
        @include arrow-transitions(0deg);
        &:hover {
            cursor: pointer;
            .arrow {
                top: 50%;
                &:before {
                    @include arrow-transitions(-40deg);
                }
                &:after {
                    @include arrow-transitions(40deg);
                }      
            }
        }
    }
    .arrow {
        position: absolute;
        left: 50%;
        transition: all .4s ease;
        &:before,
        &:after {
            transition: all .4s ease;
            content: '';
            display: block;
            position: absolute;
            transform-origin: bottom right;
            background: #FDC200;
            width: $arrow-line-width;
            height: $arrow-line-length;
            border-radius: 10px;
            transform: translate(-50%, -50%) rotateZ(-50deg);
        }
        &:after {
            transform-origin: bottom left;
            transform: translate(-50%, -50%) rotateZ(50deg);
        }
        @for $i from 1 through $total-arrows {
            &:nth-child(#{$i}) {
            opacity: 0.3 * $i;
            top: 15 + (100% * $i/5);
            }
        }
    }
}