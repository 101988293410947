@import "../../../../styles/mixins.scss";

.bars {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 0 5px;
  margin-bottom: 5px;
}

.bars > div:not(:last-child) {
  margin-right: 15px;
}

.graphic {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.label-container {
  text-align: center;
}

.label {
  color: #888888;
  font-weight: bold;
  margin: 0;
  display: block;
}

.value {
  font-weight: bold;
}

.value > span {
  font-size: 0.75em;
}

.value.energy {
  color: #e2ad33;
  background: initial;
}

.value.water {
  color: #007c9f;
  background: initial;
}
