@import "../../styles/mixins.scss";

.navbar-collapse {
  z-index: 1;
  background-color: #FFF;
}

.nav {
  font-family: Montserrat;
  font-size: 1.1rem !important;
  height: 10vh;
  background-color: #FFFFFF;
  justify-content: flex-start;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  
  .row {
    width: 100%;
    
    .navbar-nav {
      float: right;
    }
  }
  
  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    height: 10vh;
    width: 18vh;
    background-image: url("../../assets/img/logo/liteme/blue/horizontal/logo.svg");
    margin-left: 6vw;

    @include navbar-collapsed {
      margin-left: 5vw;
    }
    
    @include for-phone-only {
      height: 50px;
      background-position: center;
      margin-left: 15px;
    }
  }
  
  .nav-link {
    color: #0B5E96 !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    text-align: center;
    font-size: 1.5rem;

    &.active-tab {
      font-weight: bold;
      color: #0B5E96 !important;
    }

    &:hover {
      font-weight: bold !important;
    }

    &:focus {
      outline: none;
    }
  }

  .toggle-button-navbar {
    text-align: right;
  }

  .ml-auto {
    margin-right: 7vw;

    @include navbar-collapsed {
      margin-left: 5vw;
    }

    @include navbar-collapsed {
      margin-right: 0;
    }
  }

  .dropdown-menu {
    @include navbar-collapsed {
      text-align: center;
      border: none;
      background-color: #f7f7f7;
      border-radius: 0;
    }

    .dropdown-item {
      color: #0B5E96;
      opacity: 0.6;
      font-size: 1rem;

      &.active {
        background-color: transparent !important;
      }

      &:focus {
        outline: none;
        font-weight: bold;
      }
    }
  }

  .inner-nav-link-desktop {
    @include navbar-collapsed {
      display: none;
    }
  }

  .inner-nav-link-mobile {

    &.active-tab {
      font-weight: bold;

      .nav-link {
        color: #0B5E96 !important;
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.navbar-light {
  .navbar-toggler {
    color: rgba(11,94,150, 0.5);
    border-color: rgba(11,94,150, 0.3);

    &:focus {
      outline: none;
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(11,94,150, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}
