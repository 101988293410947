@import "../../../styles/mixins.scss";

.devices-section {
    height: auto;
    margin: 0;
    text-align: right;

    @include for-md {
        flex-direction: column-reverse;
    }

    &.inverted {
        flex-direction: row-reverse;
        text-align: left;

        @include for-md {
            flex-direction: column-reverse;
        }

        .devices-section-text-wrapper {
            padding: 0vh 10vh 0vh 0vh;

            @include navbar-collapsed {
                padding: 0;
            }
        }
    }

    .devices-section-text-wrapper {
        display: flex;
        font-size: 1.7rem;
        min-height: 80vh;
        padding: 0vh 0vh 0vh 10vh;

        @include navbar-collapsed {
            padding: 0;
        }

        @include for-md {
            text-align: justify;
        }

        .devices-section-text {
            margin: auto;
            width: 80%;

            b {
                font-family: Montserrat Bold;
            }

            @include navbar-collapsed {
                margin: auto;
            }

            @include for-phone-only {
                width: 90%;
            }
        }

        .devices-section-header {
            font-size: 2.5rem;
            font-weight: bold;
            line-height: 2.5rem;
            padding-bottom: 3vh;
            font-family: Montserrat Bold;

            @include for-phone-only {
                font-size: 2.5rem;
                line-height: 2.5rem;
                padding-bottom: 5vh;
            }
        }
    }

    .devices-section-artwork {
        margin: auto;
        display: flex;
        font-size: 1.5rem;
        padding: 5vh 3vh;

        .devices-artwork-title {
            font-size: 4rem;
            line-height: 5rem;
            font-family: Montserrat Bold;
            margin: auto;

            @include navbar-collapsed {
                font-size: 3.5rem;
                line-height: 4rem;
            }

            @include for-md {
                padding: 10vh 0;
                font-size: 4.5rem;
                line-height: 5rem;
            }

            @include for-xs {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }
        
        .devices-artwork {
            margin: auto;
            width: 20vw;

            @include for-md {
                margin: 5vh auto;
            }

            &.litemeter {
                width: 18vw;

                @include navbar-collapsed {
                    width: 30vw;
                }

                @include for-md {
                    width: 35vw;
                }

                @include for-xs {
                    width: 50vw;
                }
            }

            &.litetag {
                width: 22vw;

                @include navbar-collapsed {
                    width: 30vw;
                }

                @include for-md {
                    width: 40vw;
                }

                @include for-xs {
                    width: 60vw;
                }
            }

            &.yagua {
                width: 30vw;

                @include navbar-collapsed {
                    width: 35vw;
                }

                @include for-md {
                    width: 60vw;
                }

                @include for-xs {
                    margin: 10vh auto;
                    width: 80vw;
                }
            }

            &.sonoff_pow {
                width: 15vw;

                @include navbar-collapsed {
                    width: 20vw;
                }

                @include for-md {
                    width: 30vw;
                }

                @include for-xs {
                    width: 55vw;
                }
            }

            &.sonoff_mini {
                width: 20vw;

                @include navbar-collapsed {
                    width: 25vw;
                }

                @include for-md {
                    width: 40vw;
                }

                @include for-xs {
                    width: 60vw;
                }
            }
        }
    }
}

.devices-container {
    background-color: #F15B29;
    color: #FFFFFF;

    .devices-section {
        .devices-section-text-wrapper {
            background-color: #0B5E96;

            @include navbar-collapsed {
                min-height: 100vh;
                font-size: 1.6rem;
            }

            @include for-md {
                min-height: 70vh;
            }

            @include for-xs {
                min-height: 100vh;
            }

            &.yagua {
                @include navbar-collapsed {
                    min-height: 80vh;
                }

                @include for-md {
                    min-height: 55vh;
                }
    
                @include for-xs {
                    min-height: 80vh;
                }
            }
        }
    }

    &.integrations {
        background-color: #FDC200;

        .devices-section {
            .devices-section-text-wrapper {
                background-color: #0B5E96;

                @include navbar-collapsed {
                    min-height: 70vh;
                    font-size: 1.6rem;
                }

                @include for-md {
                    min-height: 50vh;
                    font-size: 1.6rem;
                }
            }
        }
    }
}