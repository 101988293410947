@import "../../../styles/mixins.scss";

.commerce-section {
    height: auto;
    margin: 0;
    padding: 10vh 0vh;

    &.consumption-section {
        margin-top: 10vh;
    }

    &.consumption-section,
    &.sensors-section,
    &.history-section {
        background-color: #EEEEEF;
    }

    &.report-section {
        @include for-md {
            flex-direction: column-reverse;
        }
    }

    .commerce-two-rows-section-text {
        text-align: justify;
        padding: 5vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        color: #4D4D4D;
        display: flex;
        height: auto;

        .commerce-two-rows-section-header {
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 5vh;
            text-align: left;
        }

        .commerce-two-rows-section-text-wrapper {
            margin: auto;
            width: 80vw;

            @include navbar-collapsed {
                width: 90vw;
            }
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
        }

        @include for-phone-only {
            font-size: 1rem;
        }
    }

    .commerce-two-rows-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;
        background-size: 80vw;

        @include navbar-collapsed {
            background-size: 90vw;
        }

        &.consumption-section {
            background-image: url(../../../assets/img/dashboards/commerce/commerce-consumption-section-img.png);
            min-height: 55vh;
            background-size: 85vw;

            @include for-big-screens {
                min-height: 65vh;
                background-size: 85vw;
            }

            @include navbar-collapsed {
                min-height: 40vh;
                background-size: 93.5vw;
            }

            @include for-md {
                min-height: 35vh;
            }

            @include for-phone-only {
                min-height: 20vh;
                background-size: 80vw;
            }
        }

        &.sensors-section {
            background-image: url(../../../assets/img/dashboards/commerce/commerce-sensors-section-img.png);
            min-height: 45vh;

            @include for-big-screens {
                min-height: 60vh;
            }

            @include for-phone-only {
                min-height: 15vh;
            }
        }

        &.history-section {
            background-image: url(../../../assets/img/dashboards/commerce/commerce-history-section-img.png);
            min-height: 60vh;

            @include for-big-screens {
                min-height: 90vh;
            }

            @include navbar-collapsed {
                min-height: 50vh;
            }

            @include for-phone-only {
                min-height: 25vh;
            }
        }
    }

    .commerce-two-columns-section-text {
        text-align: justify;
        padding: 5vh 5vh 5vh 20vh;
        font-size: 1.5rem;
        margin: auto;
        height: auto;
        color: #4D4D4D;
        display: flex;
        min-height: 65vh;

        .commerce-two-columns-section-text-wrapper {
            margin: auto;
        }

        .commerce-two-columns-section-header {
            font-size: 2rem;
            font-weight: bold;
            text-align: left;
        }

        &.report-section {
            padding: 5vh 15vh 5vh 5vh;

            @include navbar-collapsed {
                font-size: 1.4rem;
                padding: 5vh;
            }

            @include for-phone-only {
                font-size: 1rem;
            }
        }

        @include navbar-collapsed {
            font-size: 1.4rem;
            padding: 5vh;
            min-height: 40vh;
        }

        @include for-phone-only {
            min-height: 20vh;
            font-size: 1rem;
        }
    }

    .commerce-two-columns-section-img {
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 5vh;
        padding-bottom: 5vh;

        &.alerts-section {
            background-image: url(../../../assets/img/dashboards/commerce/commerce-alerts-section-img.png);
            background-size: 60vh;
            min-height: 50vh;

            @include for-big-screens {
                background-size: 70vh;
            }

            @include navbar-collapsed {
                background-size: 50vh;
                min-height: 60vh;
            }

            @include for-md {
                background-size: 90vw;
                min-height: 70vh;
            }

            @include for-phone-only {
                min-height: 40vh;
            }
        }

        &.report-section {
            background-image: url(../../../assets/img/dashboards/commerce/commerce-report-section-img.png);
            background-size: 45vw;
            min-height: 65vh;

            @include for-md {
                background-size: 90vw;
                min-height: 45vh;
            }

            @include for-phone-only {
                min-height: 30vh;
            }
        }
    }
}

.commerce-initial-text {
    margin: 0;
    margin-top: -1px;
    color: #4D4D4D;
    font-size: 1.8rem;
    text-align: justify;
    padding-top: 10vh;
    padding-bottom: 20vh;

    @include for-xs {
        font-size: 1.5rem;
    }

    .commerce-initial-text-col {
        margin: auto;
    }
}