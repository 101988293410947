@import "../../../styles/mixins.scss";

.home-devices-integration-section {
    height: auto;
    background-color: #F15B29;
    color: #FFFFFF;
    margin: 0;
    flex-direction: row-reverse;

    @include for-md {
        flex-direction: column-reverse;
    }

    .home-devices-integration-text-wrapper {
        background-color: #F15B29;
        color: #FFFFFF;
        display: flex;
        min-height: 75vh;
        font-size: 1.2rem;
        padding: 10vh 1vh;
        text-align: justify;

        @include for-md {
            min-height: 60vh;
        }

        .home-devices-integration-text {
            margin: auto 7vw auto auto;
            width: 80%;

            b {
                font-family: Montserrat Bold;
            }

            @include navbar-collapsed {
                margin: auto;
            }

            @include for-phone-only {
                width: 90%;
            }
        }

        .home-devices-integration-header {
            font-size: 3.5rem;
            font-weight: bold;
            line-height: 3.5rem;
            padding-bottom: 10vh;
            font-family: Montserrat Bold;

            @include for-big-screens {
                font-size: 4.5rem;
                line-height: 4.5rem;
            }

            @include navbar-collapsed {
                font-size: 3rem;
                line-height: 3rem;
            }

            @include for-md {
                text-align: center;
            }

            @include for-phone-only {
                font-size: 2.5rem;
                line-height: 2.5rem;
                padding-bottom: 5vh;
            }
        }

        a {
            color: #FFFFFF;
    
            &:hover {
              text-decoration: none;
            }
        }

        .home-devices-integration-btn {
            cursor: pointer;
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: bold;
            margin-top: 10vh;
            font-family: Montserrat Bold;
            text-align: left;
            position: relative;

            @include for-big-screens {
                font-size: 2rem;
                line-height: 2rem;
            }

            @include for-md {
                text-align: center;
            }

            @include for-phone-only {
                font-size: 1.35rem;
                line-height: 1.3rem;
            }

            .right-arrow-dashboards {
                display: inline-block;
                width: 3vmin;
                height: 3vmin;
                background: transparent;
                border-top: 1vmin solid #FFFFFF;
                border-right: 1vmin solid #FFFFFF;
                transition: all 200ms ease;
                vertical-align: middle;
                vertical-align: -webkit-baseline-middle;
                margin-left: 1vh;
                margin-top: 0.25vh;
                transform: translate3d(0,-50%,0) rotate(45deg);

                @include for-md {
                    margin-top: -1vh;
                }

                @include for-phone-only {
                    margin-left: 0.5vh;
                    margin-top: -0.2vh;
                }
                
                &:hover {
                    border-color: #0B5E96;
                    box-shadow: 0.5vmin -0.5vmin 0 #FFFFFF;
                }
                
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-40%,-60%) rotate(45deg);
                    width: 200%;
                    height: 200%;
                }
            }
        }
    }

    .home-devices-integration-artwork {
        margin: auto;
        text-align: left;
        background: url(../../../assets/img/home/devices-integration/home-devices-integration-img.svg) center center no-repeat;
        background-size: contain;
        display: flex;
        min-height: 60vh;
        font-size: 1.5rem;
        margin-top: auto;

        @include for-big-screens {
            min-height: 60vh;
        }

        @include for-md {
            min-height: 50vh;
            margin: 10vh 0 0;
        }

        @include for-phone-only {
            min-height: 40vh;
        }
    }
}