@import "../../../../styles/mixins.scss";

.faq-item {
  
    &.opened {

        .faq-item-arrow {
            transform: rotate(180deg);
        }
          
        .faq-item-answer-wrapper {
            max-height: 100rem;
            transition-timing-function: ease-in;
            transition-duration: 1s;
            transition-property: max-height;
        }
    }
  
    .faq-item-line {
        cursor: pointer;
        display: block;
        padding: 0.8rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        z-index: 2;
        position: relative;

        .faq-row {
            width: 100%;
            line-height: 2rem;

            .faq-item-question{
                font-size: 1.5rem;
                line-height: 2.5rem;
                margin: 0;
                font-weight: bold;
                color: #4D4D4D;

                @include for-phone-only {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
        }
    }
    
    .faq-item-answer-wrapper{
        max-height: 0;
        overflow: hidden;
        text-transform: ease-in;
        transition-duration: 0.5s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
        background-color: #EEEEEF;

        .faq-item-answer{
            padding: 0 1.2rem;
            line-height: 4rem;

            .faq-item-answer-paragraph {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                margin: 0;
                font-size: 1rem;
                color: #4D4D4D;
                font-weight: 300;
                line-height: 1.3;
                white-space: pre-line;
                text-align: justify;

                b {
                    font-weight: bold;
                }

                @include for-phone-only {
                    margin-left: -0.5rem;
                }
            }
        }
    }
}

.faq-item-header {
    font-size: 2rem;
    font-family: Montserrat Bold;

    &.question {
        color: #0B5E96;
    }

    &.answer {
        color: #FDC200;
    }
}

