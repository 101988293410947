@import "../../styles/mixins.scss";

.whatsapp-floating-icon {
  position: fixed;
  bottom: 3vh;
  right: 2vw;
  z-index: 1000;
  background-color: #25D366;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
