@import "../../../styles/mixins.scss";

.partners-container {
    padding: 0 3vh;
    min-height: 60vh;
    background-color: #EEEEEF;

    @include for-xs {
        padding-bottom: 0;
    }

    .partners-row {
        width: 80%;
        margin: auto;

        .partners-about-text {
            text-align: justify;
            font-size: 1.2rem;
            color: #4D4D4D;
            padding-bottom: 8vh;
        }
    }

    .partners-list-header {
        font-size: 2.5rem;
        width: auto;
        margin: auto;
        padding: 5vh 0;
        font-weight: bold;
        color: #0B5E96;
        text-align: center;
    
        @include for-big-screens {
            font-size: 2.8rem;
        }
    
        @include for-phone-only {
            font-size: 1.8rem;
        }
    }
}

.partners-list {
    text-align: center;
    margin: auto;
    margin-top: 2vh;
    padding: 5vh;
    width: 95%;
}

.commercial-representative {
    margin: auto;
}

.img-partner {
    height: auto;
    width: 20vw;
    margin-bottom: 7vh;

    &.first {
        margin-bottom: 0;
    }

    &.pmcg {
        padding-bottom: 10vh;

        @include for-xs {
            padding-bottom: 0;
        }
    }

    &.univesity {
        padding-top: 10vh;
        width: 20vh;

        @include for-xs {
            padding-top: 0;
        }
    }

    &.autonomia {
        width: 20vw;
        margin: auto;

        @include for-md {
            width: 27vw;
        }
    
        @include for-xs {
            width: 54vw;
        }
    }

    &.tradenergy {
        padding-top: 10px;

        @include for-big-screens {
            padding-top: 20px;
        }

        @include for-md {
            padding-top: 5px;
        }
    
        @include for-xs {
            padding-top: 0;
        }
    }

    @include for-big-screens {
        margin-bottom: 10vh;
    }

    @include for-md {
        width: 30vw;
    }

    @include for-xs {
        width: 60vw;
    }
}

.partners-artwork-wrapper {
    margin: auto;
    padding: 10vh 0 2vh;

    .partners-artwork {
        height: 20vh;
        width: auto;

        @include for-phone-only {
            height: 18vh;
        }
    }
}