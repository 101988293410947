@import "../../../styles/mixins.scss";

.benefits-section {
    min-height: 100vh;
    background: transparent linear-gradient(#0D6EAF, #0B5E96) 0% 0% no-repeat padding-box;
    padding: 0;
    display: flex;
    padding-bottom: 10vh;
    margin-bottom: -1px;

    .blocks {
        position: absolute;
        width: 100vw;
        left: 0;
        z-index: 0;
        opacity: 0.72;
    }

    .benefits-container {
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        z-index: 1;
        max-width: 100vw;
    }

    .benefits-title,
    .benefits-subtitle,
    .benefits-text {
        color: white;
    }

    .benefits-title {
        text-align: center;
        font-family: "Montserrat Bold";
        font-size: 40px;
        margin-bottom: 5vh;
    }

    .benefits-subheader {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include for-phone-only {
            margin-top: -70px;
            padding-left: 110px;
        }
    }

    .benefits-subtitle {
        text-align: center;
        font-family: "Montserrat Bold";
        font-size: 2rem;
        width: 100%;
        @include for-phone-only {
            font-size: 1.5rem;
            text-align: left;
        }
    }

    .benefits-text {
        font-size: 1.6rem;
        width: 40%;
        text-align: center;

        @include for-phone-only {
            width: 100%;
            font-size: 1.3rem;
            text-align: left;
        }
    }


    .timeline {
        position: relative;
        margin: 75px auto;
        max-width: 95vw;

        @include for-phone-only {
            max-width: 85vw;
        }

        &::after {
            content: '';
            position: absolute;
            width: 6px;
            background-color: white;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -3px;

            @include for-phone-only {
                left: 31px;
            }
        }

        &>div:nth-child(2) {
            margin-top: 50px;
        }

        .container {
            padding: 10px 50px;
            position: relative;
            background-color: inherit;
            width: 50%;
            margin: 0;
            color: white;

            @include for-phone-only {
                width: 100%;
                padding-left: 70px;
                padding-right: 0;
                text-align: left !important;
            }

            &::before {
                @include for-phone-only {
                    left: 60px;
                    border: medium solid white;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent white transparent transparent;
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                right: -25px;
                background: url('../../../assets/img/home/benefits/hex.svg');
                top: 15px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 1;
            }

            &.end:after,
            &.start::after {
                width: 100px;
                height: 100px;
                right: -50px;
            }

            &.start::after {
                background-image: url('../../../assets/img/home/benefits/start.svg');
                top: -60px;
                @include for-phone-only {
                    left: -19px;
                }
            }

            &.end:after {
                background-image: url('../../../assets/img/home/benefits/end.svg');
                @include for-phone-only {
                    left: -19px;
                }
            }

            &.left {
                left: 0;
                text-align: right;
            }

            &.right {
                left: 0;
                text-align: left;
                left: 50%;

                &::after {
                    left: -25px;
                }

                @include for-phone-only {
                    left: 0%;
                }
            }

            &.left::after,
            &.right::after {
                @include for-phone-only {
                    left: 5px;
                }
            }

            .content>h2 {
                font-size: 2rem;
                text-transform: uppercase;
                font-family: "Montserrat Bold";
                margin-bottom: 0;

                @include for-phone-only {
                    font-size: 1.5rem;
                }
            }

            .content>p {
                font-size: 1.6rem;

                @include for-phone-only {
                    font-size: 1.3rem;
                }
            }
        }
    }
}