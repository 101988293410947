@import "../../../styles/mixins.scss";

.home-innovation-section {
    height: auto;
    background-color: #0B5E96;
    margin: 0;

    @include for-md {
        flex-direction: column-reverse;
    }

    .home-innovation-text-wrapper {
        padding: 10vh 1vh;
        background-color: #0B5E96;
        color: #FFF;
        display: flex;
        min-height: 50vh;
        font-size: 1.2rem;

        .home-innovation-text {
            margin: auto auto auto 7vw;
            width: 80%;
            text-align: justify;

            b {
                font-family: Montserrat Bold;
            }

            @include navbar-collapsed {
                margin: auto;
            }
            
            @include for-phone-only {
                width: 90%;
            }
        }
    }

    .home-innovation-sub-section {
        background-color: #0B5E96;
        display: grid;
        min-height: 75vh;
        font-family: Montserrat Bold;

        @include for-md {
            min-height: 60vh;
        }

        @include for-phone-only {
            min-height: 40vh;
        }

        .home-innovation-artwork {
            background-color: #0B5E96;
            color: #FFFFFF;
            margin: auto;
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            height: auto;
            margin-top: 7vh;

            @include navbar-collapsed {
                margin-top: 15vh;
            }

            @include for-md {
                margin-top: auto;
            }

            @include for-phone-only {
                font-size: 2.5rem;
            }
    
            .home-innovation-img {
                background: url(../../../assets/img/home/innovation/home-innovation-img.svg) no-repeat;
                background-size: 40vh;
                background-position: center bottom;
                margin: auto;
                width: 40vh;
                height: 40vh;
                margin-bottom: 2vh;

                @include for-md {
                    background-size: 50vh;
                    width: 50vh;
                    height: 50vh;
                }

                @include for-phone-only {
                    background-size: 30vh;
                    width: 30vh;
                    height: 30vh;
                }
            }
        }
    }
}