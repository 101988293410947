@import "../../styles/mixins.scss";

.inner-nav {
  height: 5vh;
  width: 100%;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #0B5E96;

  @include navbar-collapsed {
    display: none;
  }

  .nav-links-wrapper{
    height: inherit;

    .nav-link {
      color: #FFFFFF !important;
      padding: 0 3vw !important;
      line-height: 5vh;
  
      &.selected-section {
        background-color: #FDC200;
      }
  
      &:focus {
        outline: none;
      }
  
      &:hover {
        background-color: #FDC200;
      }
    }
  }
}