@import "../../../../styles/mixins.scss";

.home-generation-section {
    margin: 5vh 0 10vh;

    .home-consumption-generation-txt {
        margin: auto;
        padding: 0 5vh 5vh;
        color: #4D4D4D;
        font-size: 1.2rem;
        text-align: left;

        @include for-phone-only {
            text-align: center;
        }
    }
}