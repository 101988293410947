.hexbar {
  position: relative;
  width: 25px;
  background-color: #64c7cc;
  margin: 7.22px 0;
  transition: height 0.5s ease-out;
}

.hexbar.energy {
  background-color: #e2ad33;
}

.hexbar.energy::before {
  border-bottom: 7.22px solid #e2ad33;
}

.hexbar.energy::after {
  border-top: 7.22px solid #e2ad33;
}

.hexbar.water {
  background-color: #007c9f;
}

.hexbar.water::before {
  border-bottom: 7.22px solid #007c9f;
}

.hexbar.water::after {
  border-top: 7.22px solid #007c9f;
}

.hexbar:before,
.hexbar:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
}

.hexbar:before {
  bottom: 100%;
}

.hexbar:after {
  left: 0;
  top: 100%;
  width: 0;
}
