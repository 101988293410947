@import "../../../styles/mixins.scss";

.join-liteme-section {
    height: auto;
    background-color: #0B5E96;
    margin: 0;
    padding-bottom: 5vh;

    @include for-md {
        padding-bottom: 0;
    }

    .join-liteme-btn-wrapper {
        padding: 10vh 1vh;
        background-color: #0B5E96;
        color: #FFF;
        display: flex;
        min-height: 50vh;
        font-size: 1.2rem;

        @include for-md {
            min-height: 20vh;
        }

        a {
            cursor: default;
            margin: auto;

            &:hover{
                text-decoration: none;
            }
        }

        .join-liteme-button {
            text-align: center;
            margin-top: 2vh;
            font-size: 3vh;
            font-weight: bold;
            width: 40vh;
            margin-bottom: 2vh;
            background-color: #FFFFFF;
            color: #0B5E96;
            border: none;
            border-radius: 50px;
            height: 8vh;
            line-height: 8vh;
            font-family: Montserrat Bold;
            border: 4px solid #FFFFFF;
            cursor: pointer;
            transform: scale(1.2);

            @include navbar-collapsed {
                transform: none;
            }
          
            &:focus {
                outline: none;
            }
          
            &:hover {
                font-size: 3.2vh;
                color: #F15B29;
            }
        }
    }

    .join-liteme-sub-section {
        background-color: #0B5E96;
        display: grid;
        min-height: 50vh;

        .join-liteme-artwork {
            background-color: #0B5E96;
            color: #FFFFFF;
            margin: auto;
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            height: auto;

            @include for-phone-only {
                font-size: 2.5rem;
            }
    
            .join-liteme-img {
                animation: float 5s ease infinite;
                max-width: 35vw;

                @include for-md {
                    max-width: 60vw;
                }

                @include for-phone-only {
                    max-width: 80vw;
                }
            }
        }
    }
}

@keyframes float {
    0% {
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(20px);
    }

}