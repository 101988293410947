@import "../../styles/mixins.scss";

.about-us-container {
    background-color: #0B5E96;

    .about-us-row {
        width: 35%;
        margin: auto;

        @include tabs-components {
            width: 45%;
        }

        @include for-md {
            width: 55%;
        }

        @include for-phone-only {
            width: 80%;
        }

        .about-us-about-text {
            text-align: justify;
            font-size: 1.2rem;
            color: #FFFFFF;
            padding-bottom: 10vh;
        }
    }

    .about-us-list-header {
        font-size: 2.5rem;
        width: auto;
        margin: auto;
        padding: 5vh 0;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
    
        @include for-big-screens {
            font-size: 2.8rem;
        }
    
        @include for-phone-only {
            font-size: 1.8rem;
        }
    }
}

.about-us-list {
    text-align: center;
    margin: auto;
    margin-top: 2vh;
    padding: 5vh;
    width: 95%;

    &.first {
        padding: 0;
        padding-top: 5vh;
    }

}

.img-partner {
    height: auto;
    width: 20vw;
    margin-bottom: 7vh;

    &.first {
        margin-bottom: 0;
    }

    &.autonomia {
        width: 17vw;

        @include for-md {
            width: 27vw;
        }
    
        @include for-xs {
            width: 54vw;
        }
    }

    @include for-big-screens {
        margin-bottom: 10vh;
    }

    @include for-md {
        width: 30vw;
    }

    @include for-xs {
        width: 60vw;
    }
}

.about-us-header-artwork-wrapper {
    margin: auto;
    padding: 10vh 0 2vh;

    .about-us-header-artwork {
        height: 20vh;

        @include for-phone-only {
            height: 18vh;
        }
    }
}