@import "../../styles/mixins.scss";

.footer-wrapper {
  background-color: #F15B29;
  width: 100%;
  min-height: 20vh;
  display: flex;
  position: static;

  @include for-phone-only {
    min-height: 32.5vh;
    padding-bottom: 12.5vh;
  }
}

.footer-logo-wrapper {
  width: 100%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 10vh 0;
  color: #FFFFFF;

  .footer-logo-col {

    &.liteme {
      text-align: left;

      @include for-phone-only {
        text-align: center;
        margin-bottom: 5vh;
      }
    }

    &.smartiks {
      margin-top: auto;
      text-align: right;

      @include for-phone-only {
        text-align: center;
      }
    }

    .footer-logo-question {
      font-family: Montserrat Bold;
      font-size: 2.5vh;
      line-height: 2.5vh;
      padding-left: 6px;

      @include for-phone-only {
        margin-top: 2.5vh;
        padding-left: 0;
        font-size: 3vh;
        line-height: 3vh;
      }
    }

    .footer-logo-answer {
      font-family: Montserrat Bold;
      font-size: 3vh;
      line-height: 3vh;
      margin-bottom: 1.5vh;
      padding-left: 6px;

      @include for-phone-only {
        padding-left: 0;
        font-size: 3.5vh;
        line-height: 3.5vh;
        margin-bottom: 2.5vh;
      }
    }

    .footer-logo-contact {
      font-size: 2.2vh;
      line-height: 2.5vh;
      padding-left: 6px;

      @include for-phone-only {
        padding-left: 0;
        font-size: 3vh;
        line-height: 4vh;
      }

      a {
        color: #FFFFFF;

        &:hover {
          text-decoration: none;
        }

        .contact-icon {
          width: 20px;
          margin-right: 10px;
        }
      }
    }

    .footer-logo-sub-text {
      font-size: 2vh;
      margin-top: 0.5vh;
      font-weight: 100;
      padding-right: 1px;

      @include for-phone-only {
        padding-right: 0;
        font-size: 2.3vh;
        margin-top: 1.5vh;
      }
    }
  }

  .footer-logo {
    width: auto;
    height: 9.5vh;

    @include for-xs {
      height: 8.5vh;
    }

    &.smartiks {
      height: 6vh;

      @include for-xs {
        height: 5.5vh;
      }
    }
  }
}