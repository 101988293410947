@import "./styles/mixins.scss";

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
    url(./assets/fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"),
    url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

* {
  margin:0;
  padding:0;
}

html {
  height: 100%;
  font-family: Montserrat;
  overflow: hidden;
  font-size: 0.95rem;
  
  @include for-really-big-screens {
    font-size: 1.1rem;
  }
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Montserrat", "Montserrat Bold", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  top: 10vh;
  position: fixed;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: inline-block;
  width: 100%;
  font-family: Montserrat;

  &.faq,
  &.dashboards {
    height: 85vh;
    top: 15vh;

    @include navbar-collapsed {
      height: 90vh;
      top: 10vh;
    }
  }
}

.page-section {
  height: 90vh;
  width: 100%;
}

.plain-link:hover{
  text-decoration: none;
}

.remove-left-right-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}