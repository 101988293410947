@import "../../../styles/mixins.scss";

.home-how-it-works-section {
    background-color: #EEEEEF;
    margin: 0;
    padding-bottom: 10vh;
    display: grid;

    .how-it-works-section-header {
        margin: 0;
        text-align: center;
        width: 100%;
        display: grid;
        padding: 5vh;
        font-size: 3rem;
        color: #0B5E96;
        font-family: Montserrat Bold;
    }

    .how-it-works-figure-section {
        width: 85vw;
        margin: auto;

        .how-it-works-img {
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 30vh;
            height: 25vh;
            margin-bottom: 5vh;
    
            &.sensor {
                background-size: 25vh;
                background-image: url(../../../assets/img/home/how-it-works/how-to-use-sensor.png);
            }
    
            &.cloud {
                background-image: url(../../../assets/img/home/how-it-works/how-to-use-cloud.png);
            }
    
            &.web {
                background-image: url(../../../assets/img/home/how-it-works/how-to-use-web.png);
            }
        }
    
        .how-it-works-text-wrapper {
            margin: 0;
            text-align: justify;
            font-size: 1.2rem;
            color: #4D4D4D;
    
            .how-it-works-header {
                color: #0B5E96;
                margin: auto;
                font-family: Montserrat Bold;
                font-size: 1.5rem;
                padding-bottom: 2vh;
            }
        }
    }
}

.how-it-works-col {
    padding: 0 1.5vw;
}