@import "../../styles/mixins.scss";

.inner-footer-wrapper {
  margin: 15vh auto 10vh;

  &.devices {
    margin: 0;
    padding: 10vh;
  }

  .inner-footer-label {
    width: 35vh;
    text-align: left;
    margin: auto;
    display: block;
    color: #4D4D4D;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;

    @include for-phone-only {
      font-size: 1rem;
      line-height: 1rem;
    }

    &.name {
      font-family: Montserrat Bold;
      font-size: 2.5rem;
      line-height: 3rem;

      @include for-phone-only {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      &.concessionary {
        text-align: center;
        font-size: 1.9rem;
        line-height: 2.4rem;

        @include for-phone-only {
          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }

      &.faq,
      &.campus {
        text-align: center;
        font-size: 2rem;
        line-height: 2.5rem;

        @include navbar-collapsed {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }

        @include for-phone-only {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }

  .inner-footer-button {
    text-align: center;
    margin-top: 2vh;
    font-size: 3vh;
    font-weight: bold;
    width: 40vh;
    margin-bottom: 2vh;
    background-color: #0B5E96;
    color: white;
    border: none;
    border-radius: 50px;
    height: 8vh;
    line-height: 8vh;
    font-family: Montserrat Bold;
  
    &:focus {
        outline: none;
    }
  
    &:hover {
        opacity: 0.85;
    }
  }
}