@import "../../styles/mixins.scss";

.home-section-header {
    width: 100%;
    color: #0B5E96;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    padding: 5vh 0;
    font-family: Montserrat Bold;

    @include for-phone-only {
        font-size: 2rem;
        padding: 5vh 3vh 3vh 3vh;
    }
}

.home-regular-text {
    margin: auto;
    padding: 0vh 5vh;
    color: #4D4D4D;
    font-size: 1.5rem;
}