@import "../../../styles/mixins.scss";

.map-section {
    background-color: #FDC200;
    margin: 0;
    padding: 5vh 0;
    display: grid;
    margin-bottom: -1px;

    @include navbar-collapsed {
        display: none;
    }
}

.map {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    display: none !important;
  }
  
.map-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    margin-top: 2%;
    overflow: hidden;
    position: relative;
}

.leaflet-container {
    height: 60vh;
    width: auto;
}

.map-label-wrapper {
    margin-left: 5%;
    margin-right: 5%;
}

.map-label {
    width: 100%;
    text-align: right;
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 400;
}