@import "../../../../../styles/mixins.scss";

.ImageSliderWrapper {
  text-align: center;
  width: 100%;
  padding: 0;

  @include for-phone-only {
    padding: 5vh 0 5vh;
  }

  .slider-img {
    height: 48vh;
    width: auto;

    @include for-big-screens {
      height: 55vh;
    }

    @include navbar-collapsed {
      height: 40vh;
    }

    @include for-phone-only {
      height: auto;
      width: 100%;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    position: absolute;
    top: 50%;
    width: 4vmin;
    height: 4vmin;
    background: transparent;
    border-top: 1vmin solid #0B5E96;
    border-right: 1vmin solid #0B5E96;
    transition: all 200ms ease;
    
    &:hover {
      border-color: #FDC200;
      box-shadow: 0.5vmin -0.5vmin 0 #0B5E96;
    }
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%,-60%) rotate(45deg);
      width: 200%;
      height: 200%;
    }
  }

  .carousel-control-next-icon {
    right: 5vw;
    transform: translate3d(0,-50%,0) rotate(45deg);

    @include for-phone-only {
      right: 0;
    }
  }

  .carousel-control-prev-icon {
    left: 5vw;
    transform: translate3d(0,-50%,0) rotate(-135deg);

    @include for-phone-only {
      left: 0;
    }
  }

  .carousel-caption {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 4vh;
    padding-bottom: 0;
    color: #000000;
    text-align: center;
  }

  .img-slide-caption {
    color: #0B5E96;
    font-size: 1.8rem;
    padding: 0 2vh;
  }
}