@import "../../../styles/mixins.scss";

.join-form-container {
    background-color: #FFFFFF;
    min-height: 90vh;
    padding: 10vh 7.5vw 2vh;
    width: 100%;
    color: #000000;

    .join-form-header-wrapper {
        width: 100%;
        display: inline-block;

        .join-form-header {
            width: 50%;
            text-align: right;
            color: #0B5E96;
            font-size: 6vh;
            font-weight: bold;
            float: right;
            line-height: 6vh;

            @include navbar-collapsed {
                font-size: 5vh;
                line-height: 5vh;
                width: 65%;
            }

            @include for-phone-only {
                font-size: 4vh;
                line-height: 4.5vh;
                width: 85%;
            }
        }
    }
    
    .form-section {
        padding-top: 5vh;

        .section-header {
            color: #0B5E96;
            font-size: 6vh;
            font-weight: bold;
    
            @include navbar-collapsed {
                font-size: 5vh;
            }
    
            @include for-phone-only {
                font-size: 4vh;
            }
        }

        .form-input-area {
            width: 100%;

            .form-text-input {
                text-align: right;
                line-height: 6vh;
                padding-top: 1vh;

                .form-text-input-header {
                    font-size: 2.9vh;
                    padding-right: 3vw;
                    vertical-align: middle;
                    color: #4D4D4D;
                }

                .form-text-input-area {
                    width: 80%;
                    font-size: 3vh;
                    padding: 0 1vh;
                    border: none;
                    background-color: #EEEEEF;
                    color: #4D4D4D;
                    outline-color: #FDC200;

                    @include navbar-collapsed {
                        width: 70%;
                    }

                    @include for-phone-only {
                        width: 60%;
                    }
                }
            }

            .form-input-textarea {
                font-size: 3vh;
                padding: 1vh 1vh;
                width: 100%;
                border: none;
                min-height: 25vh;
                background-color: #EEEEEF;
                color: #4D4D4D;
                outline-color: #FDC200;
            }

            .text-input-error {
                width: 100%;
                text-align: right;
                color: red;
                font-size: 2vh;
            }

            input.checkbox-btn {
                display: none;
            }
            
            input.checkbox-btn + label {
                width: 100%;
                font-size: 3vh;
                border: none;
                background-color: #EEEEEF;
                color: #4D4D4D;
                vertical-align: middle;
                line-height: 6vh;
                padding: 0 3vh;
            }
              
            input.checkbox-btn:not(:checked) + label:hover {
                box-shadow: 0px 1px 3px;
            }
            
            input.checkbox-btn + label:active,
            input.checkbox-btn:checked + label {
                background: #FDC200;
                color: #FFFFFF;
                opacity: 0.85;
            }
        }

        .profile-input-error {
            width: 100%;
            text-align: center;
            color: red;
            font-size: 2vh;
        }

        .profile-options {
            text-align: center;
            width: 80%;
            margin: auto;
            padding-top: 3vh;

            @include navbar-collapsed {
                width: 100%;
            }
            @include for-phone-only  {
                width: 100%;
            }

            .profile-options-col {
                padding: 0 5px;
            }

            input.radio-btn {
                display: none;
            }
            
            input.radio-btn + label {
                vertical-align: middle;
                filter: grayscale(100%);
            }
              
            input.radio-btn:not(:checked) + label:hover {
                filter: none;
                box-shadow: 0px 0px 3px;
            }
            
            input.radio-btn + label:active,
            input.radio-btn:checked + label {
                border: 2px solid #FDC200;
                filter: none;
            }

            .profile-label {
                background-color: #EEEEEF;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 25vh;
                height: 25vh;
                width: 100%;

                @include for-phone-only {
                    background-size: 15vh;
                    height: 20vh;
                }

                &.residence {
                    background-image: url(../../../assets/img/join/residence-label.png);
                }
                &.commerce {
                    background-image: url(../../../assets/img/join/commerce-label.png);
                }
                &.industry {
                    background-image: url(../../../assets/img/join/industry-label.png);
                }
                &.concessionary {
                    background-image: url(../../../assets/img/join/concessionary-label.png);
                }
                &.campus {
                    background-image: url(../../../assets/img/join/campus-label.png);
                }
                &.other {
                    background-image: url(../../../assets/img/join/other-label.png);
                }
            }
        }
    }

    .form-has-errors {
        color: red;
        font-size: 2vh;
        display: inline-block;
        padding-right: 2vw;
    }

    .join-submit-button-wrapper {
        width: 100%;
        text-align: end;
    }

    .join-submit-button {
        margin-top: 5vh;
        font-size: 3vh;
        font-weight: bold;
        width: 20vh;
        margin-bottom: 5vh;
        background-color: #0B5E96;
        color: white;
        border: none;
        border-radius: 50px;
        height: 8vh;
        font-family: Montserrat Bold;

        @include for-phone-only {
            width: 18vh;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            opacity: 0.85;
        }
    }
}

.email-sent-page {
    height: 70vh;
    display: flex;

    .email-sent-page-text {
        margin: auto;
        text-align: right;
        width: 50vw;
        font-family: Montserrat Bold;
        font-size: 2.7rem;
        color: #0B5E96;

        @include navbar-collapsed {
            width: 60vw;
            font-size: 2.5rem;
        }
        @include for-phone-only  {
            width: 80vw;
            font-size: 2rem;
        }
    }
}