@import "../../styles/mixins.scss";

.terms-and-conditions-wrapper {
    max-height: 90vh;
    overflow: auto;
}

.terms-and-conditions-header {
    font-size: 2.8rem;
    width: 75vw;
    margin: auto;
    padding: 5vh 0 0;
    font-family: Montserrat Bold;
    color: #0B5E96;
    text-align: center;
    margin-bottom: 5vh;

    @include for-big-screens {
        width: 65vw;
    }

    @include navbar-collapsed {
        width: 85vw;
    }

    @include for-phone-only {
        font-size: 2rem;
        width: 90vw;
    }
}

.terms-and-conditions-header2 {
    font-size: 1.5rem;
    width: 75vw;
    margin: auto;
    padding: 8vh 0 0;
    font-family: Montserrat Bold;
    color: #0B5E96;
    text-align: center;
    margin-bottom: 2vh;

    @include for-big-screens {
        width: 65vw;
    }

    @include navbar-collapsed {
        width: 85vw;
    }

    @include for-phone-only {
        font-size: 1rem;
        width: 90vw;
    }
}
.terms-and-conditions-text{
    margin: auto;
    padding: 0;
    max-width: 85vw;
    font-size: 1.5rem;
    margin-bottom: 20vh;
    text-align: justify;

    @include for-big-screens {
        max-width: 85vw;
    }

    @include navbar-collapsed {
        max-width: 90vw;
    }

    @include for-phone-only {
        max-width: 90vw;
    }

    .terms-and-conditions-title {
        margin: auto;
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .terms-and-conditions-title-left {
        margin: auto;
        text-align: left;
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 3vh;
    }

    .terms-and-conditions-bullets {
        margin-left: 5vw;

        @include for-phone-only {
            margin-left: 10vw;
        }
    }
}

.terms-and-conditions-title2 {
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 3vh;
}

.terms-and-conditions-title3 {
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2vh;
}